/* eslint-disable camelcase */
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  ContactDetails,
  FAQ,
  ContactForm,
  PageHeading,
  LocationSection,
} from 'components';
import { ContactUsPageType } from 'types';

const ContactUs: FC<ContactUsPageType> = ({ data }: any) => {
  const {
    ContactUsPage: {
      title,
      pageTheme,
      seo,
      banner,
      faqSection: { title: faqTitle, tagline: faqTagline, faqs },
      contactFormSection: {
        tagline: contactFormTagline,
        email,
        description: contactFormDescription,
        phone: { number: phoneNumber, text: phoneText },
      },
      locationsSection: { title: heading, tagline, description, image },
    },
  } = data;
  return (
    <PageLayout
      title="ContactUs"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      hasContainerClass
      className={`${pageTheme}`}
      delaySec={0.7}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faqs}
    >
      <div className="contact-us-section">
        <PageHeading title={title} level={1} />
        <section className="contact-us-section-container white-gradient-background">
          <div className="contact-detail">
            <ContactDetails email={email} phone={{ phoneNumber, phoneText }} />
            <div className="contact-detail-content">
              <h4>{contactFormTagline}</h4>
              <p>{contactFormDescription}</p>
            </div>
          </div>
          <ContactForm dealTitle="Contact Form" />
        </section>
        <LocationSection
          title={heading}
          tagline={tagline}
          description={description}
          image={image}
        />
      </div>
      <section className="contact-page-faq-section">
        <PageHeading title={faqTitle} />
        <div className="contact-page-faq-section-wrapper white-gradient-background">
          <h4 className="contact-page-faq-section-heading">{faqTagline}</h4>
          <FAQ Faqs={faqs} />
        </div>
      </section>
    </PageLayout>
  );
};

export default ContactUs;
export const query = graphql`
  query ContactUsPageQuery {
    ContactUsPage: strapiContactUsPage {
      title
      pageTheme
      phoneNumber
      email
      description
      locationSectionHeading
      heading
      slug
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      contactFormSection {
        title
        tagline
        email
        description
        phone {
          number
          text
        }
      }
      faqSection {
        faqs {
          title
          answer
          id
        }
        title
        tagline
      }
      locationsSection {
        title
        tagline
        description
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, height: 600)
            }
          }
        }
      }
    }
  }
`;
